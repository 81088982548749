import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  });

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} style={{ width: "80px", height: "80px" }} />
        <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
        >Tranquil Turtle Aquarium
        </s.TextTitle> 
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container>
            <s.SpacerMedium />
            <s.SpacerSmall />
            <s.TextDescription style={{ textAlign: "center", fontSize: 20, color: "var(--accent-text)" }}>
              Coming Soon
            </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.Container flex={1} jc={"center"} ai={"center"} style={{ width: "100%", opacity: 0.9, backgroundColor: "var(--accent)" }}>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 30, margin: "2% 0% 0% 0%", color: "var(--accent-text)" }}>
            Welcome to Tranquil Turtle Aquarium
          </s.TextSubTitle>
          <s.SpacerSmall />
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            Abstract
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          The Tranquil Aquarium is an ERC-721 (NFT) collection of Tranquil Turtles based on three pillars: sustainability, investment, and tangibility.
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            Sustainability
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          Each Tranquil Turtle will be paired with a real turtle through turtle rescue centers worldwide. Our partner rescue centers will receive 33% of the proceeds from every mint, as well as continual support from secondary sales. Additionally, CRCL Token (https://www.crcltoken.com/), will be the primary currency used in many aspects of the Aquarium for certain benefits.
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            Investment
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          Whether holding, reselling, breeding, boosting, or racing a Tranquil Turtle, we want community members to see their NFT’s as investments. Future developments of the collection may include, but are not limited to, earning passive income from breeding, racing turtles for crypto prizes, and exclusive access to subsequent NFT collections for free (minus gas fees).
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            Tangibility
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          We want each Tranquil Turtle to feel real. Therefore we are committed to supporting turtle rescue centers with a substantial percentage of our proceeds. We intend to obtain regular updates on our sponsor turtles so that NFT holders will feel involved. We also want this collection to have an aspect of tangible monetary benefits. This is why we are incorporating several opportunities for holders to earn crypto while holding their Tranquil Turtles.
          </s.TextDescription>
        </s.Container>
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.Container flex={1} jc={"center"} ai={"center"} style={{ width: "100%", opacity: 0.9, backgroundColor: "var(--accent)" }}>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "2% 0% 0% 0%", color: "var(--accent-text)" }}>
            Aquarium Objective
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          There are 3 main objectives of the Tranquil Aquarium. 1) Create fun and unique art for the NFT community. 2) Give back to the environment and the wonderful creatures that inspire our art by donating a large portion of the proceeds to actual sea creature rescue facilities. 3) Create a community that committed to building value far beyond the realm of NFT’s
          </s.TextDescription>
        </s.Container>
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.Container flex={1} jc={"center"} ai={"center"} style={{ width: "100%", opacity: 0.9, backgroundColor: "var(--accent)" }}>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "2% 0% 0% 0%", color: "var(--accent-text)" }}>
            Roadmap
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          After certain stages of the Tranquil Turtle minting process, the team will perform various actions.<br/>
          Mint Price: 0.04 ETH<br/>
          Max Supply: 10,000 Tranquil Turtles.
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            25% Minted
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          1) First and foremost, initial checks are sent to partner turtle rescue organizations.<br/>
          2) Initial turtle pairings will be made, giving NFT owners exclusive access to these organizations.
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            50% Minted
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          1) Exclusive lobby access with “Patron Wall” for NFT holders<br/>
          2) Gift shop with TTA swag and giveaways<br/>
          3) 5  Tranquil Turtles Airdropped to those who have minted and hold 50 million CRCL Token
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            75% Minted
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          1) Seahorse Lounge Announcement and Initiation (first Turtle companion NFT)<br/>
          2) 5 Tranquil Turtles Airdropped to those who have minted and hold 50 million CRCL Token
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            100% Minted
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          1) Turtle Hatchery Announcement<br/>
          2) Tranquil Turtle 2.0 Status Teaser<br/>
          3) First floor sweep of community-voted NFT project
          </s.TextDescription>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            Post-Mint
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          1) Seahorse Lounge Launch<br/>
          2) Turtle Hatchery Launch (Turtle Breeding, Passive Income, Baby Turtles w/ performance based statistics for CRCL Arena)<br/>
          3) Starfish Lagoon Announcement & Launch (second Turtle companion NFT)<br/>
          4) CRCL Arena (NFT gameplay & betting)
          </s.TextDescription>
        </s.Container>
        <s.Container flex={1} jc={"center"} ai={"center"} style={{ width: "100%", opacity: 0.9, backgroundColor: "var(--accent)" }}>
          <s.TextSubTitle style={{ textAlign: "left", fontSize: 24, margin: "0% 0% 0% 0%", color: "var(--accent-text)" }}>
            Giving Back
          </s.TextSubTitle>
          <s.TextDescription style={{ textAlign: "left", fontSize: 18, margin: "0% 5% 5% 5%", color: "var(--accent-text)" }}>
          Sea Turtle rescues that we are persuing partnerships with:
          <br/>
            <ul>
              <ui><a href="https://www.seaturtlesinc.org">Sea Turtles Inc.</a><br/></ui>
              <ui><a href="https://www.texasstateaquarium.org/adopt-an-animal/">Texas State Aquarium</a><br/></ui>
              <ui><a href="https://seaturtlerecovery.org/about-us/">Sea Turtle Recovery</a><br/></ui>
            </ul>
          </s.TextDescription>
        </s.Container>
        <s.SpacerMedium />
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "100%", opacity: 0.9, backgroundColor: "var(--accent)" }}>
          <s.TextDescription style={{ textAlign: "center", fontSize: 20, margin: "5% 0% 5% 5%", color: "var(--accent-text)" }}>
            WARNING: Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              fontSize: 20,
              color: "var(--accent-text)",
              margin: "0% 5% 5% 5%",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              fontSize: 20,
              color: "var(--accent-text)",
              margin: "0% 5% 5% 5%",
            }}
          >
            <a href={"https://twitter.com/aquariumturtle"} ><img alt={"Twitter"} src={"/config/images/twitter.png"} style={{ width: "30px", height: "30px" }} /></a>
            <a href={"https://discord.com/invite/m2VY8bNED9"} ><img alt={"Discord"} src={"/config/images/discord.png"} style={{ width: "30px", height: "30px" }} /></a>
            <a href={"https://t.me/CRCLToken"} ><img alt={"Telegram"} src={"/config/images/telegram.png"} style={{ width: "30px", height: "30px" }} /></a><br/>
            <a href={"https://drive.google.com/file/d/1fg0Gy9UcU25h7_-C2mLY--DlshAHKnWa/view?usp=sharing"} >Whitepaper</a>
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
